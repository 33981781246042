import { Chip, SvgIcon, Tooltip, Icon, Typography,FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ora, stampToMoment,dateToMoment, contrast } from '../../utils.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FlightIcon from '@mui/icons-material/Flight';
import LockIcon from '@mui/icons-material/Lock';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { mdiSnowflake, mdiCurtains, mdiCurtainsClosed } from '@mdi/js'
import CachedIcon from '@mui/icons-material/Cached';
//import { FlightTakeoff, FlightLand, Publish, GetApp, Cached, LocalParking, Cancel, CheckBoxOutlineBlank, Warning, AcUnit, CheckBoxOutlined, Close, SubdirectoryArrowRight, ErrorOutline} from '@mui/icons-material';
import Publish from '@mui/icons-material/Publish'
import GetApp from '@mui/icons-material/GetApp'
import { Block, Home, Lock, Warning } from "@mui/icons-material";
import { EnumField } from '../components/input/Select';

/*prevost:
export function color({ potkocsi, kocsi, sofor, tetel, borze, contact, partner, quote, order, poi }) {
  if (potkocsi) {
    let bg = '#aaaaaa';
    switch (potkocsi.pottipus) {
      case 1: bg = '#0288d1'; break;
      case 2: bg = '#ba68c8'; break;
      case 3: bg = '#ec407a'; break;
      default: break;
    }
    return { bgcolor: bg, color: '#ffffff' };
  }
  if (kocsi) {
    return { bgcolor: kocsi.alvall ? '#cc7eaa' : '#ef6c00', color: '#ffffff' }
  }
  if (sofor) {
    return { bgcolor: sofor.alvall ? '#cc7eaa' : '#ef6c00', color: '#ffffff' };
  }
  if (tetel) {
    return { bgcolor: tetel.hatter || '#ccc', color: contrast(tetel.hatter) }
  }
  if (borze) {
    return { bgcolor: ['#ccc', '#1976d2', '#ED6C02', '#2e7d32', '#d32f2f'][borze.statusz], color: '#ffffff' }
  }

  if (partner) {
    let bg = partner.deleted ? '#ccc' : (partner.color || (partner.customer && partner.subcontractor ? '#d8d' : partner.customer ? '#f22' : '#ccf'));
    return { bgcolor: bg, color: contrast(bg) };
  }

  if (contact) {
    let bg = contact.deleted ? '#ccc' : (contact.color || '#a00');
    return { bgcolor: bg, color: contrast(bg) };
  }


  if (quote) {
    let bg = '#689f38';
    switch (quote.status) {
      case 'Rejected':
      case 'Revoked':
      case 'Expired':
        bg = '#999';
        break;
    }
    return { bgcolor: bg, color: contrast(bg) };
  }
  if(order){
    let bg = order.lemondva? '#dcc' : (order.hatter || '#ffffff');
    return { bgcolor: bg, color: contrast(bg) };
  }

  if (poi) {
    if (poi.tipus === 1) return {bgcolor: '#ab47bc', color: '#ffffff' };
    return {bgcolor: '#9c27b0', color: '#ffffff' };
  }


  return { bgcolor: '#ffffff', color: '#222222' };
}*/

/* Gönczi */
export function color({ potkocsi, kocsi, sofor, tetel, borze, contact, partner, quote, order, poi }) {
  if (potkocsi) {
    if(potkocsi.leiras && potkocsi.leiras.includes('rövid')) return { bgcolor: '#607D8B', color: '#ffffff' };
    return { bgcolor: '#00ACC1', color: '#ffffff' };
  }
  if (kocsi) {
    if(kocsi.leiras && kocsi.leiras.includes('rövid')) return { bgcolor: '#607D8B', color: '#ffffff' };
    if(kocsi.leiras && kocsi.leiras.includes('12')) return { bgcolor: '#FFEB3B', color: '#ffffff' };
    return { bgcolor: kocsi.alvall ? '#cc7eaa' : '#ef6c00', color: '#ffffff' }
  }
  if (sofor) {
    return { bgcolor: sofor.alvall ? '#cc7eaa' : '#ef6c00', color: '#ffffff' };
  }
  if (tetel) {
    return { bgcolor: tetel.hatter || '#ccc', color: contrast(tetel.hatter) }
  }
  if (borze) {
    return { bgcolor: ['#ccc', '#1976d2', '#ED6C02', '#2e7d32', '#d32f2f'][borze.statusz], color: '#ffffff' }
  }

  if (partner) {
    let bg = partner.deleted ? '#ccc' : (partner.color || (partner.customer && partner.subcontractor ? '#d8d' : partner.customer ? '#f22' : '#ccf'));
    return { bgcolor: bg, color: contrast(bg) };
  }

  if (contact) {
    let bg = contact.deleted ? '#ccc' : (contact.color || '#a00');
    return { bgcolor: bg, color: contrast(bg) };
  }


  if (quote) {
    let bg = '#689f38';
    switch (quote.status) {
      case 'Rejected':
      case 'Revoked':
      case 'Expired':
        bg = '#999';
        break;
    }
    return { bgcolor: bg, color: contrast(bg) };
  }
  if(order){
    let bg = order.lemondva? '#dcc' : (order.hatter || '#ffffff');
    return { bgcolor: bg, color: contrast(bg) };
  }

  if (poi) {
    if (poi.tipus === 1) return {bgcolor: '#ab47bc', color: '#ffffff' };
    return {bgcolor: '#9c27b0', color: '#ffffff' };
  }


  return { bgcolor: '#ffffff', color: '#222222' };
}

export function DetailPopup({ children }) {
  return children;
}

export function Cim({ orszag, irsz, pdisplay }) {
  return <>
    <Typography variant="body2" sx={{ color: 'text.secondary' }} component="span">{orszag} {irsz}</Typography>
    <Typography variant="body2" component="span">{pdisplay}</Typography>
  </>
}

export const Tapa = VisibilityIcon;
export const Negykez = PeopleIcon;
export const Modul = ListAltIcon;
export const NincsAdr = WarningAmberIcon;
export const KlmCert = FlightIcon;
export const NyitasFigyeles = LockIcon;

export const Pharma = MedicalServicesIcon;

export function Mega() {
  return <div style={{ display: "inline-block", position: 'relative', top: -3, left: -2, width: 7, height: 7, overflow: 'display', fontSize: 9, fontStyle: 'italic' }}>M</div>//<Icon icon="arrow-up" />
}

export function TetelJelleg({ tetel, ...other }) {
  let Icon = null;
  switch (tetel.jelleg) {
    case 1: Icon = Publish; break;
    case 2: Icon = CachedIcon; break;
    case 3: Icon = CachedIcon; break;
    case 4: Icon = GetApp; break;
    case 6: Icon = Home; break;
    default: break;
  }
  return Icon && <Icon {...other} />
}

export function Pottipus({ pottipus, ...other }) {
  if (!pottipus) return null;
  let path = [mdiSnowflake, mdiCurtains, mdiCurtainsClosed][pottipus - 1];
  return <SvgIcon {...other}><path d={path} /></SvgIcon>
}


export function PottipusTag({ pottipus }) {
  if (!pottipus) return null;
  let label = ['Hűtő', 'Ponyva', 'Doboz'][pottipus - 1];
  return <ITag show icon={<Pottipus pottipus={pottipus} />}>{label}</ITag>
}

export function ITag({ show, children, icon }) {
  if (!show) return null;
  return <Chip icon={icon} label={children} variant="outlined" size="small" color="primary" />
}

export function Comment({ comment }) {
  if (!comment || !comment.length) return null;
  let c = comment.filter(m => m.tipus === 2 || m.tipus === 3);
  if (!c.length) return null;
  return <div>
    {comment.map(m => {
      let icon = null;
      if (m.tipus === 2) icon = <Warning   fontSize="small" />;
      if (m.tipus === 3) icon = <Block  fontSize="small" />;
      return <Typography style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    }} variant="body2" key={m.sorszam}>{icon} {m.memo} {m.ekezdet && dateToMoment(m.ekezdet).format('MM.DD.')} {m.eveg && dateToMoment(m.eveg).format('MM.DD.')}</Typography>;
    })}
  </div>
}


const pstr = ['', 'Nincs', '9h', '11h', '24h', '45h', '1 hét', 'Kilépő'];
export function pihenostr(piheno) {
  return pstr[piheno];
}

export function Piheno({ piheno, maradek }) {
  return <span>
    {piheno ? '[' + pihenostr(piheno) + ']' : ''}
    {maradek > 0 ? ` (marad: ${maradek / 60}h)` : ''}
  </span>
}


export function Celpont({ tetel, full }) {
  return <ITag style={color({ tetel })}>
    {tetel.pdisplay} <Ora ido={tetel.eterv} />
  </ITag>
}

export function Ora({ ido, format, style, inside }) {
  let txt = format ? stampToMoment(ido).format(format) : ora(ido);
  let st = style || { fontWeight: 'bold' };
  if (inside) {
    return <Typography variant="body2" component="span" style={st}>{txt}</Typography>;
  }

  return <Tooltip title={stampToMoment(ido).format('YYYY.MM.DD HH:mm')}>
    <Typography variant="body2" component="span" style={st}>{txt}</Typography>
  </Tooltip>

}


export function potOK(potkocsi, tetel) {
  if (tetel.frigo && potkocsi.pottipus !== 1) return false;
  if (tetel.tapa && !potkocsi.tapa) return false;
  if (tetel.pharma && !potkocsi.pharma) return false;
  if (tetel.mega && !potkocsi.mega) return false;
  return true;
}

export function Atakasztas(props) {
  return <CachedIcon {...props} />
}

export function PihenoEditor(props) {
  return <EnumField {...props} options={[
    {value:'1', label:'Nincs'},
    {value:'2', label:'9h'},
    {value:'3', label:'11h'},
    {value:'4', label:'24h'},
    {value:'5', label:'45h'},
  ]}/>
}


export function MaradekEditor(props) {
  return <EnumField {...props} options={[
    {value:'0', label:'Nincs'},
    {value:'60', label:'1h'},
    {value:'120', label:'2h'},
    {value:'180', label:'3h'},
    {value:'240', label:'4h'},
    {value:'300', label:'5h'},
  ]}/>
}

export function FunkcioEditor(props){
  return <EnumField 
  name="func" 
  label="Funkciók" 
  options={[
    { value:'számlázás',label:'számlázás'}
  ]} 
  {...props}/>
}