import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'
import '../../../node_modules/typeface-roboto-multilang/latin-ext.css';
import config from '../config.json';

import { WindowDimensionsProvider } from '../components/Responsive';
import { SnackbarProvider } from 'notistack';
import { LocaleContext } from '../locales';
import { FetchContext, useRefresh, useJSON, setPrefix } from '../data/fetch';
import { Loading } from '../components/Loading';
import OrderPanel from './OrderPanel';
import { Helmet } from "react-helmet";
import { withStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';


setPrefix('/webdata/');

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div'
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px'
        }
      }
    }
  },
  palette: {
    primary: { main: config.theme && config.theme.primary ? config.theme.primary : undefined },//Gönczi
    secondary: { main: config.theme && config.theme.secondary ? config.theme.secondary : undefined },//Gönczi
    background: {
      default: '#eee',
    }
  }
});

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <WindowDimensionsProvider>
          <FetchContext>
            <LocaleContext locale="hu">
              <ConfirmProvider>
                <Helmet>
                  <title>Subcontractor interface [ t c a p ]</title>
                </Helmet>
                <OrderWindow>
                  <Router>
                    <Switch>
                      <Route path='/'>
                        <OrderPanel />
                      </Route>
                    </Switch>
                  </Router>
                </OrderWindow>
              </ConfirmProvider>
            </LocaleContext>
          </FetchContext>
        </WindowDimensionsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}


const styles = theme => ({
  root: {
  },
  header: {
    height: 68,
    background: 'url(gonczi_header_bg.png)',
    backgroundRepeat: 'repeat-x',
    paddingLeft: 50,
    // borderBottom: `4px solid ${theme.palette.primary.main}`,
    marginBottom: 10,
  },
});

let OrderWindow = ({ children, classes }) => {
  return <Box sx={{ bgcolor: 'background.paper', minHeight: '100vh' }}>
    <Box className={classes.header} >
      <img src="gonczi_header.png" alt="Gönczi Transport Kft." />
    </Box>
    <Container fixed maxWidth="sm">
      {children}
    </Container>
  </Box>
}

OrderWindow = withStyles(styles)(OrderWindow);

export default App;