import React, { useState } from 'react';
import { Grid, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cn from 'classnames';
import { dateToMoment, stampToMoment, oraperc } from '../../utils.js';
import { TetelJelleg } from '../ui/alapadat';
import { useSidebar } from '../components/Sidebar';
import CheckIcon from '@mui/icons-material/Check';

export function Erkezes({ tetel }) {
  if (tetel.eterv) {
    let st = stampToMoment(tetel.eterv);
    return <>
      <span className="datum" style={{ fontWeight: 'bold' }}>{st.format('MM.DD. ddd')}</span>
      <span className="ora">{st.format('HH:mm')}</span>
    </>;
  }
  if (!tetel.date) return null;
  let nap = dateToMoment(tetel.date);
  return <>
    <span className="datum" style={{ fontWeight: 'bold' }}>{nap.format('MM.DD. ddd')}</span>
    <span className="ora">{tetel.arrival || ''}</span>
  </>;
}

export function Indulas({ tetel }) {
  if (tetel.iterv) {
    let st = stampToMoment(tetel.iterv);
    return <span className="ora">{st.format('HH:mm')}</span>;
  }

  if (!tetel.departure) return null;
  return <span className="ora">{tetel.departure}</span>
}

export function Tetel({ tetel, rakodo, active, start, expanded, end, onClick }) {
  let cim;
  if (expanded) {
    cim = <div onClick={onClick}>
      <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>
        {tetel.orszag} {tetel.irsz} {tetel.varos}, {tetel.cim}
      </Typography>
      <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>{tetel.epulet}</Typography>
    </div>
  } else {
    cim = <Typography variant="caption" component="div" sx={{ color: "text.secondary" }}>{tetel.orszag}{tetel.irsz && tetel.irsz.substr(0, 2)} {tetel.prnev}</Typography>
  }
  let nyitvatart = null;
  let arrival = [];
  let cargo = [];
  let info = null;

  if (rakodo) {
    if (rakodo.nyitvatart) nyitvatart = <Typography key="nyitvatart" variant="caption" component="div" sx={{ color: "text.secondary" }}>Nyitvatartás: {rakodo.nyitvatart}</Typography>;
    if (rakodo.date && rakodo.date !== tetel.eterv.substring(0, 10)) arrival.push(rakodo.date.replaceAll('-','.'));
    if (rakodo.time) arrival.push(rakodo.time);
    if (rakodo.arrival) arrival.push(rakodo.arrival);
    if (rakodo.arrivalend) arrival.push(' - ' + rakodo.arrivalend);
    arrival = arrival.length ? <Typography key="arrival" variant="caption" component="div" sx={{ color: "text.secondary" }}>Elvárt érkezés: {arrival.join(' ')}</Typography> : null;

    if(rakodo.mennyiseg) {
      cargo.push(`${rakodo.mennyiseg} ${rakodo.me}`);
    }
    if(rakodo.suly) cargo.push(`${rakodo.suly} kg`);
    if(rakodo.cargo) cargo.push(rakodo.cargo);
    if(rakodo.rakszam) cargo.push(rakodo.rakszam);
    if(rakodo.cargomemo) cargo.push(rakodo.cargomemo);
    cargo = cargo.length?  <Typography key="cargo" variant="caption" component="div" sx={{ color: "text.secondary" }}>{cargo.join(' | ')}</Typography> : null;
    if(tetel.soforinfo) info = <Typography key="info" variant="caption" component="div" sx={{ color: "text.secondary" }}>{tetel.soforinfo}</Typography>;
  }
  return <Typography component="div" variant="body2" className={cn('tetel', { active, hasprev: !start, hasnext: !end })} onClick={onClick}>
    <div className='left'></div>
    <div className={cn('right', ['felrako', 'akasztas', 'akasztas', 'lerako', 'tankolas', 'via'][tetel.jelleg - 1])}>
      <div className="primary" style={{ width: 130 }}><TetelJelleg tetel={tetel} fontSize="12" /> <span>{tetel.rnev || tetel.varos}</span></div>
      <Erkezes tetel={tetel} expanded />
      <Indulas tetel={tetel} expanded />
      {cim}
      {nyitvatart}
      {arrival}
      {cargo}
      {info}
    </div>
  </Typography>
}


export function UtvonalData({ tetel, rakodo, expanded }) {
//  console.log("Útvonal", { tetel, rakodo });
  const { setSidebar, open } = useSidebar();
  return <div className="timeline" style={{ paddingLeft: 4 }}>
    {tetel.map((u, i) => {
      return <Tetel
        key={i}
        expanded={expanded}
        tetel={u}
        rakodo={rakodo && u.rakodo && rakodo[u.rakodo - 1]}
        active
        start={!i}
        end={i === tetel.length - 1}
      />
    })}
  </div>
}

