import { D, GPS, C, UI, I, F, M, DT, B, N, TEXT, PARAM, autoinc, readonly, pkey, notnull, notgen } from './types.js';


export default {

	tetel: {
		sorszam: UI(8, 'Sorszám', { pkey , autoinc}),
		tcapid : UI(8, "tcapid"),
		jarat: C(14, 'Járat'),
		megbizas: UI(8, 'Megbizas'),
		jelleg: N('Jelleg', ['Felrakó', 'Leakasztás', 'Felakasztás', 'Lerakó', 'Tankolás', 'Via pont', 'Raktár lerakó', 'Raktár felrakó']),
		kapocs: UI(8, 'Kapocs'),
		rejtett: B('Rejtett'),

		feladat: UI(8, 'Feladat ID'),
		fstamp: DT('SMS értesítés'),
		icellid: UI(8, 'Icell üzenet'),
		tdriverid: UI(8, 'tdriver üzenet'),
		tdfuvar: UI(8, 'tdriver fuvar'),

		pont: UI(8, 'Pont'),
		pdisplay: C(3, 'Kód'),
		prnev: C(60, 'Rövidnév', { notgen }),
		korzet: C(20, 'Körzet'),
		eterv: DT('Tervezett érkezés'),
		eteny: DT('Tényleges érkezés'),
		iterv: DT('Tervezett indulás'),
		iteny: DT('Tényleges indulás'),
		sterv: F('Súly terv', { prec: 4 }),
		steny: F('Súly teny', { prec: 4 }),
		km: F('Km óra állás', { prec: 1 }),
		nyitvatart: C(30, 'Nyitvatartás'),

		kocsi: C(12, 'Vontató'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),
		sofor2: C(30, 'Sofőr 2'),
		epulet: C(60, 'Épület'),
		irsz: C(16, 'Irsz'),
		varos: C(30, 'Város'),
		cim: C(40, 'Cím'),
		orszag: C(2, 'Ország'),
		plat: GPS('Lat'),
		plon: GPS('Lon'),
		erkezes: DT('Érkezés (terv vagy tény)'),
		kstart: DT('Érkezés (késés)'),
		eok: B('OK'),
		iok: B('OK'),
		aruid: C(30, 'EKÁER szám'),
		aru: TEXT('Áru'),
		menny: F('Mennyiség'),
		me : C(10, 'Egység'),
		sterv: F("Húzott súly"),

		rakref: C(30, 'Rakodási referencia'),
		rakmemo: C(30, 'Megjegyzés'),

		//vezénylés:
		vezenyles: N('Vezénylés', ['Érkezés', 'Indulás']),
		piheno: N('Pihenő', ['Nincs', '9', '11', '24', '45', '1 hét', 'Kilépő']),
		uj: B('Új'),
		foghato: DT('Fogható'),
		mfoghato: DT('Fogható'),
		soformemo: C(60, 'Megjegyzés'),
		info: C(100, 'Szakasz infó'),
		elouto: N('Elő/utó rakás', ['Előrakás', 'Utórakás']),
		maradek: I(4, 'Maradék vezidő (perc)'),
		cmaradek: I(4, 'Maradék vezidő (számolt)'),
		mstatusz: N('Megrendelés státusz', ['Előkészítés alatt', 'Megrendelve', 'Megszervezve', 'Megkezdve', 'Lebonyolítva', 'Pénzügyileg rendezve', 'Lemondva', 'Lemondva (Fizetős)', 'Lemondva és rendezve'], { notgen, readonly }),
		mtipus: N('Típus', ['Normál', 'Üres', 'Szerviz'], { notgen, readonly }),
		frigo: N('Frigo', ['Frigo', 'Frigo külön kérésre'], { notgen, readonly }),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz'], { notgen, readonly }),
		menetrend: N('Menetrend szerint', ['Hűtő', 'Ponyva', 'Doboz'], { notgen, readonly }),
		elteres: B('Eltérés a megállapodástól', { notgen, readonly }),
		nemtankol: B('Nem tankol', { notgen, readonly }),
		kiemelt: B('Kiemelt', { notgen, readonly }),
		tapa: B('TAPA', { notgen, readonly }),
		mega: B('MEGA', { notgen, readonly }),
		pharma: B('Pharma', { notgen, readonly }),
		fleiras: C(250, 'Feladat leírása', { notgen, readonly }),
		pothutes: B('Pótkocsi hűtés figyelés', { notgen, readonly }),
		poth_bfok: B('Hőfoktól', { notgen, readonly }),
		poth_tfok: B('Hőfokig', { notgen, readonly }),
		eloter: C(10, 'Előtér', { notgen, readonly }),
		hatter: C(10, 'Háttér', { notgen, readonly }),
		referencia: C(30, 'Referencia', { notgen, readonly }),
		viszonylat: C(30, 'Viszonylat', { notgen, readonly }),
		refkm: F('Referencia km', { notgen, readonly }),

		// km elszámolás
		elszamolt: D('Elszámolás dátuma'),
		steny: F("Húzott súly"),
		tkm: F('Megtett km'),
		soforkm: N('Km elszámolás', ['Normál', 'Oktató', 'Tanuló', 'Indusztria'], { defval: 1 }),
		sofor2km: N('Km elszámolás', ['Normál', 'Oktató', 'Tanuló', 'Indusztria'], { defval: 1 }),

		//szakasz logika
		kezdet: DT('Feladatrész kezdete', { readonly }),
		mbjarat: C(14, 'Megbízás vagy Járat', { readonly }),
		szkezdet: DT('Szakasz kezdete', { readonly }),

		vankilenc: N('9 órás pihenő', ['Már nincs', 'Még van']),
		xpotkocsi: C(12, 'Akasztott pótkocsi'),

	},

	kocsi: {
		frsz: C(12, "Rendszám", { pkey }),
		etip: N('Típus', ['Saját (teher)', 'Saját (egyéb)', 'Alvállalkozó']),
		alvall: UI(8, 'Alvállakozó'),
		tipus: C(30, "Típus"),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz'], { notgen }),
		jelleg: N('Jelleg', ['Önjáró', 'Vontatmány', 'Csereszekrény']),
		leiras: C(30, 'Leírás'),
		ptipus: C(30, "Típus"),
		plomba: C(30, "Plomba"),
		kme: F("Elöl (m)"),
		kmk: F("Középen (m)"),
		kmh: F("Hátul (m)"),
		bme: F("Elöl (m)"),
		bmk: F("Középen (m)"),
		bmh: F("Hátul (m)"),
		vegzaro: UI(5, "Végzáró"),
		csgatlo: C(30, "Csúszásgátló"),
		elvedo: C(30, "Élvédő"),
		pokroc: C(30, "Pokróc"),
		potnyitas: B('Nyitás érzékelő'),
		pothutes: B('Hűtés érzékelő'),
		tapa: B('TAPA'),
		pharma: D('Pharma'),
		tdriver: UI(10, 'tdriver'),
		_torolve: B('törölve')

	},

	sofor: {
		rnev: C(30, 'Rövid név', { pkey }),
		etip: N('Típus', ['Saját (teher)', 'Saját (egyéb)', 'Alvállalkozó']),
		alvall: UI(8, 'Alvállakozó'),
		nev: C(30, 'Név'),
		//		szabadkap: B('megjelenik'),
		adr: D('ADR'),
		klmcert: D('KLM'),
		tapa: D('TAPA'),
		pharma: D('Pharma'),
		modul: B('Modul szerint dolgozik'),
		charter: B('Charter szerint dolgozik'),
		kezdo: B('Kezdő'),
		elorako: B('Előrakó'),
		mciklus: UI(4, 'Munka ciklus'),
		pciklus: UI(4, 'Pihenő ciklus'),
		_torolve: B('törölve')
	},

	regio: {
		kod: C(20, 'Kód', { pkey }),
		sorrend: UI(8, 'Sorrend'),
		plat: GPS('Lat'),
		plon: GPS('Lon'),
		sugar: UI(3, 'Sugár'),
		orszag: C(2, 'Orszag')
	},

	orszag: {
		kod: C(2, 'Kód', { pkey }),
	},

	korzet: {
		kod: C(20, 'Kód', { pkey }),
		sorrend: UI(8, 'Sorrend')
	},

	valtasigeny: {
		sorszam: UI(8, 'Sorszám', { pkey }),
		datum: D('Dátum'),
		sofor: C(30, 'Sofőr'),
		irany: N('Irány', ['Érkezés', 'Indulás']),
		memo: C(60, 'Memo')
	},

	stamp: {
		stamp: DT('stamp'),
	},

	gcomment: {
		sorszam: UI(8, 'Sorszám', { pkey }),
		kocsi: C(12, 'Vontató'),
		sofor: C(30, 'Sofőr'),
		tipus: N('Típus', ['Megjegyzés', 'Figyelmeztetés', 'Tiltás']),
		ekezdet: D('Érvény kezdete'),
		eveg: D('Érvény vége'),
		value: C(250, 'Érték'),
		szin: N('Szín', ['Piros','Sárga','Kék']),
		figy: N('Figyelmeztetés', ['Színes keret']),
		memo: C(1000, 'Szöveg'),
		_torolve: B('Törölve'),
		xauto: B('xauto'),
		xetetel: UI(8, 'xetetel'),
		xatable: C(30, 'xatable'),
		xafield: C(30, 'xafield'),
		xuser: C(20, 'xuser'),
		xstamp: DT('xstamp')
	},

	pont: {
		sorszam: UI(8, 'Sorszám', { pkey }),
		tipus: N('Típus', ['Rakodópont', 'Átakasztópont', 'Pihenőhely', 'Telephely', 'Határátkelő', 'Benzinkút', 'Via pont']),
		rnev: C(30, 'Rövid név'),
		display: C(3, 'Rövidités'),
		epulet: C(60, 'Épület'),
		irsz: C(16, 'Irsz'),
		varos: C(30, 'Város'),
		cim: C(40, 'Cím'),
		orszag: C(2, 'Ország'),
		korzet: C(60, 'Körzet'),
		nyitvatart: C(30, 'Nyitvatartás'),
		info1: C(250, 'Sofőr infók'),
		info2: C(250, 'Planner infók'),
		info3: C(250, 'Elérhetőségek'),
		belso: C(250, 'Figyelmeztetés'),
		plat: GPS('Lat'),
		plon: GPS('Lon'),
		plat1: GPS('Lat1'),
		plon1: GPS('Lon1'),
		plat2: GPS('Lat2'),
		plon2: GPS('Lon2'),
		atakaszto: B('Törölve'),
		_torolve: B('Törölve'),
	},


	tcapevent: {
		id: UI(8, 'Sorszám', { pkey }),
		muvelet: C(40, 'Művelet'),

		szakasz: C(60, 'Szakasz azonosító'),
		jarat: C(14, 'Járat'),
		tetel: UI(8, 'Tétel'),
		felrako: UI(8, 'Felrakó'),
		megbizas: UI(8, 'Megbízás'),

		sofor: C(30, 'Sofőr'),
		sofor2: C(30, 'Sofőr2'),
		kocsi: C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		felelos: C(20, 'Fuvarfelelős'),
		tkm: F('Tervezett km'),
		eterv: DT('Eterv'),
		pont: UI(8, 'Pont'),
		piheno: N('Pihenő', ['Nincs', '9', '11', '24', '45', '1 hét', 'Kilépő']),
		maradek: I(4, 'Maradék idő (perc)'),
		memo: C(1000, 'Memó'),
		borze: UI(10, 'Börze'),
		bstatusz: N('Börze státusz', ['Függőben', 'Opció', 'Siker', 'Kudarc', 'Visszavonva']),

		user: C(20, 'Felhasználó'),
		stamp: DT('Rögzítés'),

		xstatus: N('Státusz', ['Rendben', 'Hibás']),
		xstamp: DT('Feldolgozva'),
		xmemo: C(1000, 'Eredmény')
	},


	fuvarborze: {
		id: UI(8, 'Sorszám', { pkey }),
		esedekes: DT('Esedékes'),

		szakasz: C(60, 'Szakasz azonosító'),
		jarat: C(14, 'Járat'),
		tetel: UI(8, 'Tétel'),
		megbizas: UI(8, 'Megbízás'),

		sofor: C(30, 'Sofőr', { notgen, readonly }),
		sofor2: C(30, 'Sofőr2', { notgen, readonly }),
		kocsi: C(12, 'Kocsi', { notgen, readonly }),
		potkocsi: C(12, 'Pótkocsi', { notgen, readonly }),
		mstatusz: N('Megrendelés státusz', ['Előkészítés alatt', 'Megrendelve', 'Megszervezve', 'Megkezdve', 'Lebonyolítva', 'Pénzügyileg rendezve', 'Lemondva', 'Lemondva (Fizetős)', 'Lemondva és rendezve'], { notgen, readonly }),

		memo: C(1000, 'Leírás'),
		user: C(20, 'Felhasználó'),
		stamp: DT('Rögzítés'),

		statusz: N('Státusz', ['Függőben', 'Opció', 'Siker', 'Kudarc', 'Visszavonva']),
		xstamp: DT('Feldolgozva'),
		xmemo: C(1000, 'Eredmény'),
		xuser: C(20, 'Felhasználó')
	},


	kocsiborze: {
		id: UI(8, 'Sorszám', { pkey }),
		esedekes: DT('Esedékes'),

		tetel: UI(8, 'Tétel'),

		sofor: C(30, 'Sofőr', { notgen, readonly }),
		sofor2: C(30, 'Sofőr2', { notgen, readonly }),
		kocsi: C(12, 'Kocsi', { notgen, readonly }),
		potkocsi: C(12, 'Pótkocsi', { notgen, readonly }),

		memo: C(1000, 'Leírás'),
		user: C(20, 'Felhasználó'),
		stamp: DT('Rögzítés'),

		statusz: N('Státusz', ['Függőben', 'Opció', 'Siker', 'Kudarc', 'Visszavonva']),
		xstamp: DT('Feldolgozva'),
		xmemo: C(1000, 'Eredmény'),
		xuser: C(20, 'Felhasználó')
	},




	jarat: {
		pos: C(14, 'Járatszám', { notnull, readonly, pkey }),
		telep: N('Telephely', ['(V)egyes', '(L)ufthansa'], { notnull, defval: 1 }),
		eidoszak: C(7, 'Eredmény időszak'),
		viszonylat: C(30, 'Viszonylat'),
		minta: B('Minta'),
		tipus: N('Tipus', ['Saját', 'Alvállalkozói', 'Vegyes'], { defval: 1 }),
		jelleg: N('Járat jelleg', ['Normál', 'Belföldi', 'Spedició', 'Industria'], { defval: 1 }),
		alvall: UI(8, 'Alvállalkozó'),
		felelos: C(20, 'Fuvarfelelős'),
		kezdet: DT('Kezdet', { readonly }),
		gyujto: B('Gyüjtő'),
		tkm: F('Referencia km'),
		akm: F('Átállás km')
	},


	megbizas: {
		kod: UI(8, 'Sorszám', { autoinc, readonly, pkey }),

		tipus: C(30, 'Megbízás tipus'),
		jdij: C(30, 'Fuvardíj jogcím'),
		jallas: C(30, 'Állasdíj jogcím'),
		jvam: C(30, 'Vám jogcím'),
		jbizt: C(30, 'Biztosítás jogcím'),
		dij: M('Megbízási díj'),
		allas: M('Állasdíj'),
		vam: M('Vámkezelési díj'),
		bizt: M('Biztosítási díj'),
		ktgcel: M('Önköltség'),
		deviza: C(3, 'Deviza'),
		kdeviza: C(3, 'Deviza'),

		viszonylat: C(30, 'Viszonylat'),
		minta: B('Minta'),
		ref: C(60, 'Referencia', { notnull }),
		jarat: C(14, 'Járat'),
		kmegbizas: UI(8, 'Kapcsolódó megbízás'),
		statusz: N('Státusz', ["Előkészítés alatt, Megrendelve, Megszervezve, Megkezdve, Lebonyolítva, Pénzügyileg rendezve, Lemondva, Lemondva (Fizetős), Lemondva és rendezve"], { readonly }),
		mtipus: N('Megbízás típus', ['Normál', 'Üres átállás', 'Szervíz']),
		kezdet: DT('Kezdet', { readonly }),
		datum: D('Dátum'),
		partner: UI(8, 'Partner'),
		statpartner: UI(8, 'Statisztikai partner'),
		pcontact: UI(8, 'Kapcsolat'),
		uzletkoto: C(20, 'Üzletkötő'),
		lcim: TEXT('Levcim'),
		lemail: C(120, 'Email küldés'),
		csop1 : C(100,  'csoport #1'),
		csop2 : C(100,  'csoport #2'),
		csop3 : C(100,  'csoport #3'),
	},

	mtipus: {
		rnev: C(30, 'Rövid név', { pkey }),
		jelleg: N('Jelleg', ['Keretszerződés, Egyedi Ajánlat']),
		jdij: C(30, 'Fuvardíj jogcím'),
		jallas: C(30, 'Állasdíj jogcím'),
		jvam: C(30, 'Vám jogcím'),
		jbizt: C(30, 'Vám jogcím'),
		ajdij: C(30, 'Fuvardíj jogcím'),
		ajallas: C(30, 'Állasdíj jogcím'),
		ajvam: C(30, 'Vám jogcím'),
		ajbizt: C(30, 'Vám jogcím'),
		deviza: C(3, 'Deviza')
	},


	ajanlat: {
		id: UI(8, 'Sorszám', { autoinc, readonly, pkey }),
		kod: C(20, 'Kód'),
		ref: C(30, 'Referencia szám'),
		tetel: PARAM("Tétel"),
		partner: PARAM("Partner"),
		contact: PARAM("Kontakt"),
		eredet: C(20, 'Eredet'),
		vip: B('VIP'),
		viszonylat: C(250, 'Viszonylat'),
		statusz: N('Státusz', ["Előkészítés alatt, Kiajánlva, Megrendelve, Lemondva, Lejárt"], { readonly }),
		statuszdesc: C(250, 'Státusz'),
		wizzard: UI(2, 'Itt tart a piszkozat'),
		lejar: DT('Lejárat'),
		lejarnap: UI(3, 'Lejárat'),
		kiadva: DT('Kiadva'),
		felelos: C(20, 'Kiadta'),
		fuvardij: M('Fuvardíj'),
		kmdij: M('Km díj'),
		allasdij: M('Állás díj'),
		pmemo: TEXT('Partner Megjegyzés'),
		memo: TEXT('Megjegyzés'),
		via: PARAM('Via pontok'),
		waypoints: PARAM('Útvonal tervezési pontok'),
		utvonal: PARAM('Útvonal'),
		utemezes: PARAM('Ismétlődő feladat ütemezése'),
		rezsi: PARAM('Rezsi'),
		calc: PARAM('Kalkulációs alap'),
		arres: PARAM('Árrés'),
		onkoltseg: PARAM('Önköltség'),
		price: PARAM('Kalkulált ár'),
	},


	td_eszkoz: {
		id: UI(10, 'Azonosító', { autoinc }),
		subscription: PARAM('PushMessage subscription'),
		eszkoz: C(30, 'Eszköz azonosító'),
		statusz: N('Státusz', ['Telepítés alatt', 'Engedélyezve', 'Letiltva'], { defval: 1 }),
		kocsi: C(12, 'Rendszám'),
		naplozas: N('Naplózás', ['Kikapcsolva', 'Hibák', 'Alap', 'Részletes'], { defval: 2 }),
		eletjel: UI(4, 'Életjel (mp)', { defval: 60 }),
		tel: C(30, 'Telefonszám'),
		resetcode: C(10, 'Regisztrációs kód')
	},

	knaplo: {
		sorszam: UI(10, 'Azonosító', { pkey, autoinc }),
		forrasid: C(60, 'Forras #id', { readonly }),
		celid: C(60, 'Cél #id', { readonly }),
		xid: C(60, 'ID', { readonly, notgen }),
		stamp: DT('Időpont', { readonly }),
		irany: N('Irány', ['Bejövő', 'Kimenő'], { notnull }),
		media: N('Média', ['SMS', 'Email', 'Icell'], { notnull }),

		jarat: C(14, 'Járat'),
		kocsi: C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),

		msg: TEXT('Üzenet'),
		msg_hu: TEXT('Fordítás'),

		rnev: C(30, 'Record típus', { readonly }),
		ertek: TEXT('Értékek', { readonly }),
		nyelv: N('Nyelv', ['Magyar', 'Román']),
		csatolas: B('Csatolás'),
		tetel: UI(8, 'Tétel'),
		sablon: C(30, 'Sablon'),
		sablonreply: C(30, 'Válasz sablonra'),

		nyugta: DT('Nyugta'),
		rejtett: B('Rejtett'),
		trresult: N('Eredmény', ['Sikeres', 'Hibás']),
		trtxt: C(250),

		sendtour: DT('Útvonalküldés'),
		prioritas: N('Prioritás', ['Normál', 'Sürgős']),
		tabletfile: C(250, 'Talet file'),

		valasz: N('Visszajelzést kérünk', ['5 percen belül', '10 percen belül', '15 percen belül', '30 percen belül', '60 percen belül']),
		timeout: DT('Választ várunk eddig'),
		reply: DT('Választ kaptunk'),
		warning: DT('Figyelmeztettünk'),

		cuser: C(20, "tport user"),
		created: DT("Létrehozva"),
		kikuldve: DT('Kiküldve'),
		hibacnt: UI(3, 'Hiba'),
		kezbesitve: DT('Kézbesítve'),
		letoltve: DT('Letöltve'),
		hivatkozas: UI(8, 'Válasz erre'),
		nysofor: C(30, 'Nyugtázta'),
		lat: GPS('Lat'),
		lon: GPS('Lon'),
		km: UI(8, 'Km óra állás most'),
		utolsokm: UI(8, 'Utolsó állása'),
		virtualkm: UI(8, 'Virtuális km'),
		legvonal: UI(8, 'Légvonal'),
		td_statusz: TEXT('Státusz txt'),
		upload: C(250, 'Csatolmány'),
		uuid: C(250, 'UUID'),
		reply_options: TEXT('Válasz opciók', { notgen, readonly }),
		keses: B('Késés', { notgen, readonly }),
		fparam: C(250, 'fparam')
	},

	td_sablon: {
		rnev: C(30, 'Azonosítás', { pkey }),
		irany: N('Irány', ['Bejövő', 'Kimenő']),
		autokuldes: B('Automatikus küldés'),
		ismetles: N('Ismétlés', ['Mindig', 'Naponta', 'Hetente', 'Havonta']),
		cnt: UI(3, 'Max hányszor'),
		autornev: C(30, 'Erre válaszként'),
		text_hu: TEXT('Szövegr'),
		href_hu: C(250, 'Hivatkozás'),
		opt1_hu: TEXT('Opciók'),
		opt2_hu: TEXT('Opciók'),
		reply_hu: TEXT('Válasz opciók'),

		text_ro: TEXT('Szövegr'),
		href_ro: C(250, 'Hivatkozás'),
		opt1_ro: TEXT('Opciók'),
		opt2_ro: TEXT('Opciók'),
		reply_ro: TEXT('Válasz opciók'),

		mailto: C(250, 'Email'),
		smsto: C(250, 'SMS')
	},

	td_fuvar: {
		id: UI(8, 'Azonosító', { pkey, autoinc }),
		jarat: C(14, 'Járat'),
		viszonylat: C(30, 'Viszonylat'),
		mref: C(20, 'Referencia'),
		megbizas: UI(8, 'Megbízás'),
		szakasz: UI(8, 'Szakasz'),
		kocsi: C(12, 'Kocsi'),
		potkocsi: C(12, 'Pótkocsi'),
		sofor: C(30, 'Sofőr'),
		sofor2: C(30, 'Sofőr #2'),
		msg: TEXT('Üzenet'),
		feladat: TEXT('Feladat'),
		leiras: TEXT('Útvonal'),
		ttxt1: TEXT('Pótkocsi felvétele'),
		ttxt2: TEXT('Áru összetétele'),
		ttxt3: TEXT('Pótkocsi leadása'),
		ttxt4: TEXT('Egyéb infó'),
		lpihi1: TEXT('Pihenő'),
		fpihi1: B('Fizetős'),
		lpihi2: TEXT('Pihenő'),
		fpihi2: B('Fizetős'),
		lpihi3: TEXT('Pihenő'),
		fpihi3: B('Fizetős'),
		frigo: N('Frigo', ['Frigo', 'Frigo külön kérésre']),
		pottipus: N('Póttipus', ['Hűtő', 'Ponyva', 'Doboz']),
		megapot: B('Mega'),
		pothutes: B('Pótkocsi hűtés figyelés'),
		poth_bfok: B('Hőfoktól'),
		poth_tfok: B('Hőfokig'),
		pottxt: TEXT('Pótkocsi'),
	},

	partner: {
		sorszam: UI(8, 'Sorszám', { pkey }),
		rnev: C(30, 'Rövidnév'),
		nev1: C(30, 'Név'),
		nev2: C(30, 'Név'),
		mnev: C(255, 'Többsoros név'),
		irsz: C(16, 'Irsz'),
		varos: C(30, 'Város'),
		cim: C(40, 'Cím'),
		orszag: C(2, 'Ország'),
		jelleg: N('Jelleg', ['Szállító', 'Vevő']),
		fizmod: N('Fizetési mód', ['Készpénz', 'Átutalás', 'Kompenzáció']),
		fizhatar: UI(4, 'Határidő (nap)'),
		tcapid: UI(8, "TCAP sorszám"),
		language: C(10, "Nyelv"),
		nyform: C(20, 'Nyomtatási forma'),
		adoszam: C(20, 'Adószám'),
		csopado: C(20, 'Csoportos adószám'),
		lcim: TEXT('Levcim'),
		lemail: C(120, 'Email küldés'),
		sbank: C(20,'Saját bank'),
		_torolve: B('Törölve')
	},

	contact: {
		sorszam: UI(8, "Sorszám", { pkey }),
		rnev: C(30, "Rövidnév"),
		nev: C(30, "Név"),

		partner: UI(8, "Partner"),
		tel: C(20, 'Telefon'),
		email: C(60, 'Email'),
		mobil: C(20, 'Mobil'),
		megszol: C(20, 'Megszólitás'),

		irsz: C(16, 'Irsz'),
		varos: C(30, 'Város'),
		cim: C(40, 'Cím'),
		orszag: C(2, 'Ország'),
		_torolve: B('Törölve')
	},

	platenrgroup: {
		id: UI(8, 'Azonosító', { pkey, autoinc }),
		name: C(60, 'Megnevezés'),
		frsz: PARAM('Rendszámok'),
		cuser: C(20, 'Felhasználó'),
		created: DT('Létrehozva')
	},
	emailaccount:{
		email: C(100,'Email',{pkey}),
		username: C(100,'Username'),
		psw: C(100,'Psw'),
		host: C(100,'Host'),
		port: UI(5,'Port'),
		secure: B('secure'),
		tls: B('tls'),
	}
};
