import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { withStyles } from '@mui/styles';
import { Typography, Box, Paper, Button, Grid, Chip, ButtonGroup, LinearProgress } from '@mui/material';
import { format, dateToMoment } from '../../utils.js';
import { postCommand, useRefresh, useJSON } from '../data/fetch';
import { useConfirm } from 'material-ui-confirm';
import { useLocale } from '../locales';

const styles = theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    padding: 16,
    background: theme.palette.background.paper,
    zIndex: 1,
    maxWidth: 450,
  },
});

let Uploads = props => {
  const { classes, action, a } = props;
  let { uploads } = a;
  const confirm = useConfirm();
  const refresh = useRefresh();
  const [uploading, setUploading] = useState(false);
  const { __ } = useLocale();


  const onDrop = useCallback(async acceptedFiles => {
    setUploading(true);
    console.log('dropped', acceptedFiles);
    for (let i = 0; i < acceptedFiles.length; i++) {
      let formData = new FormData();
      formData.append("upload", acceptedFiles[i]);
      const response = await fetch(`/webdata/uploads/${a.cipher}` , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          //          'X-Access-Token': getToken(),
        },
        body: formData
      });
      console.log("uploaded", response);
    }
    setUploading(false);
    console.log("refresh");
    refresh();
    //setDialog({ id: 'tender', op: 'update', rec: value });
  }, [a.cipher, setUploading, refresh])
  //  console.log("Orderalapadatok", a);

  const deleteFile = useCallback((ev, file) => {
    ev.preventDefault();
    ev.stopPropagation();
    confirm({
      title: __('subc.upload.delete'),
      description: __('subc.upload.warn'),
      confirmationText: __('subc.upload.ok'),
      cancelText: __('subc.upload.cancel'),
    })
      .then(() => {
        fetch(`/webdata/uploads/${a.cipher}/${file}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
          },
        })
        refresh();
      })
      .catch(() => { /* meggondolta magát */ });
  }, [a.cipher, confirm, refresh,__]);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Box sx={{ bgcolor: isDragActive ? "#efe" : 'background.paper', boxShadow: 4, borderRadius: 2, p: 2, }}>
      <Typography variant="caption">{__('subc.upload.title')}</Typography>
      <div {...getRootProps({
        onClick: event => {
          //          event.preventDefault();
          event.stopPropagation();
        }
      })}>
        <input {...getInputProps()} />

        {uploads && uploads.length? uploads.map(f => <Chip key={f}
          component="a"
          href={`/webdata/uploads/${a.cipher}/${f}`}
          target="_blank"
          variant="outlined"
          clickable
          onDelete={ev => deleteFile(ev, f)}
          label={f} />) : 
          <Typography variant="body2" style={{ textAlign: 'center', color: "#ccc" }}>{__("subc.upload.placeholder")}</Typography>
        }
        {uploading && <LinearProgress />}
      </div>
    </Box>
  )
}

Uploads = withStyles(styles)(Uploads);

export default Uploads;