import React, { useCallback, useEffect, useState } from 'react';
import { ResponsiveLayout } from '../components/Responsive';
import OrderAlapadatok from './OrderAlapadatok';
import { useJSON, fetchJSON } from '../data/fetch';
import { useSidebar } from '../components/Sidebar'
import { useDialog } from '../components/Dialog'
import { Header } from '../components/Header';
import { Link } from '../components/Link';
import { Formik, useField } from 'formik';
import { Panel, useTab, PageTabs, PanelField } from '../components/Panel';
import Loading from '../components/Loading';
import { color } from '../ui/alapadat';
import { withStyles } from '@mui/styles';
import { Box, Stack, Grid, Container, Avatar, Button, Paper, Typography, Checkbox, InputAdornment } from '@mui/material';
import { stampToMoment, stampFromMoment, format } from '../../utils.js';
import SendIcon from '@mui/icons-material/Send';
import { isMatch } from 'lodash';
import { FlightLand, FlightTakeoff, Pause, PlayArrow, Save, Check } from '@mui/icons-material';
import { StampField, TextField } from '../components/input';
import { EVENT } from '../../common';
import { useLocale } from '../locales';
import Uploads from './Uploads';
import moment from 'moment';

const styles = theme => ({
  root: {
    top: 0, bottom: 0, left: 0, right: 0
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    //    boxShadow: theme.shadows[4],
    zIndex: 2,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    background: '#eee',
    flexGrow: 2,
    padding: 16,
    overflowY: 'auto'

  },
  right: {
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    minWidth: 460,
    maxWidth: 460,
    overflowY: 'hidden',
    zIndex: 2,
  }

});

const Partner = ({ name, street, city, country, zip, vatnr }) => {
  const { __, __date, __enum } = useLocale();
  return <Grid container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="caption">{__('subc.billing.caption')}</Typography>
      <Typography variant="body2">
        <b>{name}</b><br />
        {country} {zip} {city}, {street}<br />
        {__('subc.billing.vatnr')}: <b>{vatnr}</b>
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <Typography variant='body2' style={{ fontSize: '0.75rem' }}>
        {__('subc.billing.warning')}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant='body2' style={{ fontSize: '0.75rem' }}>{__("subc.terms")}</Typography>
    </Grid>
  </Grid>
}


let OrderWindow = ({ a, partner, classes }) => {
  const { __, __date, __enum, locale, stampformat } = useLocale();
  const hu = locale === 'hu';


  const validate = useCallback((val) => {
    let err = {};
    try {
      let stformat = stampformat[locale];
      let vanido = false;
      let stampError = (st) => {
        if (!st) return false;
        if (!hu && st.length !== stformat.length) return __('subc.stamp.incomplete');
        let m = moment(st, stformat);
        if (!m.isValid() || m > moment()) return __('subc.stamp.error');
        vanido = true;
        return false;
      }

      for (let i = 0; i < a.tetel.length; i++) {
        let e = stampError(val[`erkezes_${i}`]);
        if (e) err[`erkezes_${i}`] = e;
        e = stampError(val[`indulas_${i}`]);
        if (e) err[`indulas_${i}`] = e;
        if (!val[`indulas_${i}`] && (val[`suly_${i}`] || val[`mennyiseg_${i}`])){
          err[`indulas_${i}`] = __('subc.stamp.error');
        }
      }

      if (vanido && !val.kocsi) {
        err.kocsi = __('subc.noplatenr');
      }
    } catch (e) {
      console.log("error", e);
    }

    console.log("validate", err);
    return err;
  }, [__, hu]);

  let event = false;
  const sendPlatenrs = async (val) => {
    console.log("send x", val);
    let needsrefresh = false;
    const POST = async ev => {
      console.log("POST", ev);
      needsrefresh = true;
      await fetchJSON('POST', 'json/' + a.cipher, ev);
    }
    let { kocsi, potkocsi } = val;
    if (a.tetel[0].kocsi !== kocsi || a.tetel[0].potkocsi !== potkocsi) {
      await POST({ event: EVENT.RESOURCE_ASSIGNED, kocsi, potkocsi });
    }

    let vanido = false;
    let voltido = false;
    let lastido = false;
    let voltlastido = false;
    for (let i = 0; i < a.tetel.length; i++) {
      let erk = val[`erkezes_${i}`];
      if (erk) erk = stampFromMoment(moment(erk, stampformat[locale]));
      if (erk && a.tetel[i].eteny !== erk) {
        console.log("Megváltozott az érkezés", a.tetel[i].eteny, erk);
        await POST({
          event: EVENT.TRUCK_ARRIVED, pos: a.pos, tetel: a.tetel[i].sorszam, ido: erk, kocsi: val.kocsi,
          potkocsi: val.potkocsi
        });
      }

      let ind = val[`indulas_${i}`];
      if (ind) ind = stampFromMoment(moment(ind, stampformat[locale]));
      let steny = val[`suly_${i}`];
      let tmenny = val[`mennyiseg_${i}`];

      if ((ind && a.tetel[i].iteny !== ind) || (steny && a.tetel[i].tsuly != steny) || (tmenny && a.tetel[i].tmenny != tmenny)) {
        console.log("Megváltozott az indulas", a.tetel[i].iteny, ind);
        await POST({
          event: a.tetel[i].jelleg === 1 || a.tetel[i].jelleg === 3 ? EVENT.TRUCK_LOADED : EVENT.TRUCK_UNLOADED,
          pos: a.pos,
          tetel: a.tetel[i].sorszam,
          ido: ind,
          suly: steny,
          mennyiseg: tmenny,
          kocsi: val.kocsi,
          potkocsi: val.potkocsi,
        });
      }
      if (!vanido) vanido = (erk || ind);
      if (a.tetel[i].eteny || a.tetel[i].iteny) voltido = true;
      lastido = (erk || ind);
      voltlastido = a.tetel[i].eteny || a.tetel[i].iteny;
    }

    if (vanido && !voltido) {
      console.log("most kezdte meg", vanido);
      await POST({ event: EVENT.EXECUTION_STARTED, pos: a.pos, ido: vanido });
    }

    if (lastido && !voltlastido) {
      console.log("most fejezte be", lastido);
      await POST({ event: EVENT.EXECUTION_FINISHED, pos: a.pos, ido: lastido });
    }

    if (needsrefresh) window.location.reload();

  }

  console.log("OrderWindow", a.uploads);
  const Stamp = hu ? StampField : TextField;
  let defval = { kocsi: a.tetel[0].kocsi, potkocsi: a.tetel[0].potkocsi };
  for (let i = 0; i < a.tetel.length; i++) {
    if (a.tetel[i].eteny) defval[`erkezes_${i}`] = stampToMoment(a.tetel[i].eteny).format(stampformat[locale]);
    if (a.tetel[i].iteny) defval[`indulas_${i}`] = stampToMoment(a.tetel[i].iteny).format(stampformat[locale]);
    if (a.tetel[i].tsuly) defval[`suly_${i}`] = a.tetel[i].tsuly;
    if (a.tetel[i].tmenny) defval[`mennyiseg_${i}`] = a.tetel[i].tmenny;

  }
  console.log("Defval", defval);

  return <Formik initialValues={defval} enableReinitialize={true} validate={validate} onSubmit={sendPlatenrs}>{
    ({ handleSubmit, isSubmitting, errors }) => (
      <form>


        <Stack spacing={1}>
          <Typography
            variant="h6"
            sx={{ color: 'primary.main', textAlign: 'center', width: '100%' }}>
            {__('subc.order')} {a.pos}
          </Typography>
          <Box sx={{ bgcolor: 'background.paper', boxShadow: 4, borderRadius: 2, p: 2, }}>
            <Partner {...partner} />
          </Box>


          {a.tetel.map(({ sorszam, orszag, irsz, varos, jelleg, cim, epulet, eterv, rakodo, mennyiseg, me, suly, cargomemo, eteny, iteny }, idx) => {
            let d = eterv.substring(0, 10);
            let t = eterv.substring(11, 5);

            console.log("eteny", eteny);
            eteny = stampToMoment(eteny).format(stampformat[locale]);
            console.log("eteny", eteny);

            if (rakodo) {
              let r = a.rakodo[rakodo - 1];
              let arrival = [];
              if (r.time) arrival.push(r.time);
              if (r.arrival) arrival.push(r.arrival);
              if (r.arrivalend) arrival.push(' - ' + r.arrivalend);
              d = __date(r.date);
              if (arrival.length) t = arrival.join(' ');
            }
            let cargo = [];
            if (mennyiseg) cargo.push(`${mennyiseg} ${__enum("me", me)}`);
            if (suly) cargo.push(`${suly} kg`);

            return <Box sx={{ bgcolor: 'background.paper', boxShadow: 4, borderRadius: 2, p: 2, }} key={sorszam}>
              <Typography variant="caption">{jelleg === 1 ? __('subc.loading') : __('subc.unloading')}</Typography>
              <div style={{ display: 'flex' }}>
                <Typography variant="body2" style={{ flexGrow: 1 }}><b>{orszag} {irsz} {varos}, {cim}</b><br />{epulet}</Typography>
                <Typography variant="body2"><b>{d}</b><br />{t}</Typography>
              </div>
              {jelleg === 1 && <Grid container>
                <Grid sx={12} md={6}>
                  <Typography variant="body2" sx={{ mt: 2 }}><b>{__enum("eszkoz", a.eszkoz)}</b>
                    {cargo.length && a.eszkoz && <br />}
                    {cargo.join(', ')}
                    {cargomemo && <br />}
                    {cargomemo}
                  </Typography>
                </Grid>
                <Grid sx={12} md={6}>
                  <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between', marginTop: 10 }}>
                    <TextField type="number" label={__('subc.mennyiseg')} name={`mennyiseg_${idx}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {__enum("me", me)}
                          </InputAdornment>
                        ),
                      }}

                    />
                    <TextField label={__('subc.suly')} name={`suly_${idx}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            kg
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
              </Grid>}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <div><Stamp InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightLand />
                    </InputAdornment>
                  ),
                }} name={`erkezes_${idx}`} label={__('subc.arrival')}
                  placeholder={__('subc.stamp.placeholder')}
                /></div>
                <div>
                  <Stamp InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FlightTakeoff />
                      </InputAdornment>
                    ),
                  }} name={`indulas_${idx}`} label={__('subc.departure')}
                    placeholder={__('subc.stamp.placeholder')}
                  /></div>
              </div>
            </Box>
          }
          )}

          <Box sx={{ bgcolor: 'background.paper', boxShadow: 4, borderRadius: 2, p: 2, }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField name="kocsi" label={__('subc.truck')} />
              </Grid>
              <Grid item xs={6}>
                <TextField name="potkocsi" label={__('subc.trailer')} />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button startIcon={<Save />} onClick={() => {
                  event = false;
                  handleSubmit();
                }} disabled={isSubmitting}>{__('subc.save')}</Button>
              </Grid>
            </Grid>
          </Box>
          <Uploads a={a} />
        </Stack >
      </form>
    )}
  </Formik >

}

OrderWindow = withStyles(styles)(OrderWindow);

export default ({ sidebar, match }) => {

  const data = useJSON({ url: '/json' + window.location.pathname });
  const { locale, setLocale } = useLocale();
  console.log("OrderPanel", data, match, locale, setLocale);
  useEffect(() => {
    if (data && (data.partner.language || 'hu') !== locale) {
      console.log('át kell váltani a nyelvet', data.partner.language, locale);
      setLocale(data.partner.language);
    }
  }, [data]);

  if (!data) {
    return <Loading />
  } else {
    let a = data.order;
    return <OrderWindow a={a} partner={data.partner} />
  }
}
